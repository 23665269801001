import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import logo from '../images/logo@2x.png';
import logoInverted from '../images/logo-inverted@2x.png';
import twitter from '../images/twitter-black@2x.png';
import twitterWhite from '../images/twitter-white@2x.png';
import linkedin from '../images/linkedin-black@2x.png';
import linkedinWhite from '../images/linkedin-white@2x.png';
import './Layout.scss';
import MobileNav from './MobileNav';

const menuItemsLocales = {
  pl: [
    {
      title: 'O Instytucie',
      href: '/',
    },
    {
      title: 'O nas',
      href: '/o-nas/',
    },
    {
      title: 'Nasze projekty',
      href: '/nasze-projekty/',
    },
    // {
    //   title: 'Współpraca',
    //   href: '/wspolpraca/',
    // },
    {
      title: 'Kontakt',
      href: '/kontakt/',
    },
  ],
  en: [
    {
      title: 'About the Institute',
      href: '/en/',
    },
    {
      title: 'About us',
      href: '/en/about-us/',
    },
    {
      title: 'Our projects',
      href: '/en/our-projects/',
    },
    // {
    //   title: 'Partners',
    //   href: '/en/partners/',
    // },
    {
      title: 'Contact',
      href: '/en/contact/',
    },
  ],
};

const Layout = ({ children, locale = 'pl' }) => {
  const metadata = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          linkedInUrl
          twitterUrl
          description
          title
        }
      }
    }
  `);

  const menuItems = menuItemsLocales[locale];

  return (
    <>
      <header className="header">
        <Link
          to={menuItems[0].href}
          title="Strona Główna"
          className="header__logo-link"
        >
          <img src={logo} alt="REFORM" className="header__logo" />
        </Link>
        <nav className="header__menu">
          <div className="header__menu-items">
            {menuItems.map((item) => (
              <Link
                to={item.href}
                className="header__menu-item"
                activeClassName="is-active"
                key={item.title}
              >
                {item.title}
              </Link>
            ))}
          </div>
          <div className="header__social">
            <a
              href={metadata.site.siteMetadata.twitterUrl}
              title="Twitter"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={twitter}
                alt="Twitter"
                className="header__social-icon"
              />
            </a>
            <a
              href={metadata.site.siteMetadata.linkedInUrl}
              title="LinkedIn"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={linkedin}
                alt="LinkedIn"
                className="header__social-icon"
              />
            </a>
          </div>
          <div className="header__lang">
            <Link
              to="/"
              className={locale === 'pl' ? 'is-active' : ''}
              title="Polski"
            >
              PL
            </Link>{' '}
            |{' '}
            <Link
              to="/en/"
              className={locale === 'en' ? 'is-active' : ''}
              title="English"
            >
              EN
            </Link>
          </div>
          <MobileNav data={menuItems} locale={locale} />
        </nav>
      </header>
      <main>{children}</main>
      <footer className="footer">
        <div className="footer__content-wrapper">
          <Link
            to={menuItems[0].href}
            className="footer__logo-link"
            title="Strona Główna"
          >
            <img src={logoInverted} alt="REFORM" className="footer__logo" />
          </Link>
          <span className="footer__copyright">
            &copy; {new Date().getFullYear()} Fundacja Instytut Reform. All
            Rights Reserved
          </span>
          <div className="footer__social">
            <a
              href={metadata.site.siteMetadata.twitterUrl}
              title="Twitter"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitterWhite} alt="Twitter" />
            </a>
            <a
              href={metadata.site.siteMetadata.linkedInUrl}
              title="LinkedIn"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedinWhite} alt="LinkedIn" />
            </a>
          </div>
          <span className="footer__address">
            Fundacja Instytut Reform
            <br />
            ul. Puławska 12/3, 02-566 Warszawa
            <br />
            NIP: 5213951414
            <br />
            KRS: 0000941460
          </span>
          <a
            href="mailto:office@ireform.eu"
            title="E-mail"
            className="footer__email"
          >
            office@ireform.eu
          </a>
        </div>
      </footer>
    </>
  );
};

export default Layout;

import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import './MobileNav.scss';
import mobileNavImg from '../images/mobile-nav.png';
import twitterWhite from '../images/twitter-white@2x.png';
import linkedinWhite from '../images/linkedin-white@2x.png';
import logoInverted from '../images/logo-inverted.png';
import xImg from '../images/x.svg';

const LanguageSelect = ({ locale }) => (
  <>
    <Link to="/" className={locale === 'pl' ? 'is-active' : ''}>
      Polski
    </Link>{' '}
    |{' '}
    <Link to="/en/" className={locale === 'en' ? 'is-active' : ''}>
      English
    </Link>
  </>
);

const MobileNav = ({ data, locale = 'pl' }) => {
  const metadata = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          linkedInUrl
          twitterUrl
          description
          title
        }
      }
    }
  `);

  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        type="button"
        className="mobile-nav__trigger"
        onClick={handleOpen}
      >
        <img src={mobileNavImg} alt="Open menu" />
      </button>
      {isOpen && (
        <div className="mobile-nav__backdrop" onClick={handleClose}></div>
      )}
      <CSSTransition
        classNames="mobile-nav"
        timeout={300}
        in={isOpen}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <nav className="mobile-nav">
          <div className="mobile-nav__header">
            <img
              src={logoInverted}
              alt="Open menu"
              className="mobile-nav__logo"
            />
            <div className="mobile-nav__lang">
              <LanguageSelect locale={locale} />
            </div>
            <button className="mobile-nav__close" onClick={handleClose}>
              <img src={xImg} alt="Close" />
            </button>
          </div>
          <div className="mobile-nav__lang">
            <LanguageSelect locale={locale} />
          </div>
          <div className="mobile-nav__menu">
            <hr />
            {data.map((item) => (
              <Link
                to={item.href}
                className="mobile-nav__menu-item"
                key={item.title}
              >
                {item.title}
              </Link>
            ))}
            <hr />
          </div>
          <div className="mobile-nav__social">
            <a
              href={metadata.site.siteMetadata.twitterUrl}
              title="Twitter"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitterWhite} alt="Twitter" />
            </a>
            <a
              href={metadata.site.siteMetadata.linkedInUrl}
              title="LinkedIn"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedinWhite} alt="LinkedIn" />
            </a>
          </div>
        </nav>
      </CSSTransition>
    </>
  );
};

export default MobileNav;
